import { useCallback, useState } from 'react';
import { IHostProps } from '@wix/yoshi-flow-editor';

export const useProductFacetsDialog = (
  host: IHostProps,
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const { blockScroll, unblockScroll } = host;

  const open = useCallback(() => {
    setIsOpen(true);
    blockScroll();
  }, [setIsOpen, blockScroll]);

  const close = useCallback(() => {
    setIsOpen(false);
    unblockScroll();
  }, [setIsOpen, unblockScroll]);

  return [isOpen, open, close];
};
