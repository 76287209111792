import * as React from 'react';

import { ISearchEventDocument, EventType } from '@wix/client-search-sdk';
import {
  withEnvironment,
  InjectedEnvironmentProps,
} from '@wix/yoshi-flow-editor';

import { IListLayoutItemProps } from '../Layout.types';
import { Title } from '../Title';
import { ListItemThumbnail } from '../ListItemThumbnail';
import { hasImage } from '../Thumbnail';

import { classes as descriptionClasses } from '../Description/Description.st.css';
import { st, classes } from './EventListItem.st.css';

export enum EventListItemRenderMode {
  default = 'default',
  condensed = 'condensed',
}

interface IEventListItemProps
  extends IListLayoutItemProps,
    InjectedEnvironmentProps {
  renderMode?: EventListItemRenderMode;
}

class EventListItemComp extends React.Component<IEventListItemProps> {
  static defaultProps = {
    renderMode: EventListItemRenderMode.default,
  };

  renderTime(
    { startDate }: ISearchEventDocument,
    formatDate: IEventListItemProps['formatDate'],
    formatTime: IEventListItemProps['formatTime'],
  ) {
    return startDate ? (
      <div data-hook="event-start" className={classes.eventDateTime}>
        <span data-hook="event-start-date">{formatDate(startDate)}</span>
        <span>&nbsp;|&nbsp;</span>
        <span data-hook="event-start-time">{formatTime(startDate)}</span>
      </div>
    ) : null;
  }

  getTranslatedEventTypeLabel({
    eventType,
    minPrice,
    maxPrice,
    currency,
  }: ISearchEventDocument) {
    const { formatCurrency, t } = this.props;

    const translationMap: Partial<
      Record<EventType, () => string | undefined>
    > = {
      [EventType.paid]: () => {
        const translationKey =
          minPrice !== maxPrice
            ? 'searchResults.list.events.eventTypeLabel.paid'
            : 'searchResults.list.events.eventTypeLabel.paid.singlePrice';
        return currency
          ? t(translationKey, {
              minPrice: formatCurrency(minPrice, currency),
              maxPrice: formatCurrency(maxPrice, currency),
            })
          : undefined;
      },
    };

    const translation = translationMap[eventType];
    return translation ? translation() : undefined;
  }

  getEventType(event: ISearchEventDocument) {
    return this.getTranslatedEventTypeLabel(event);
  }

  renderEventType(event: ISearchEventDocument) {
    const translation = this.getEventType(event);
    if (!translation) {
      return null;
    }
    return (
      <div className={classes.eventType} data-hook="event-type">
        {translation}
      </div>
    );
  }

  renderDescription() {
    const { renderMode, item, formatDate, formatTime } = this.props;

    const event = item as ISearchEventDocument;
    return (
      <div className={descriptionClasses.root}>
        {this.renderTime(event, formatDate, formatTime)}
        <div
          className={classes.eventLocation}
          data-hook="event-location"
          dangerouslySetInnerHTML={{ __html: event.location }}
        />
        {renderMode === EventListItemRenderMode.default &&
          this.renderEventType(event)}
      </div>
    );
  }

  renderTitle = () => {
    const { renderMode, onLinkClick, item } = this.props;
    const title = (
      <Title
        title={item.title}
        url={item.url}
        onClick={(e) => onLinkClick?.(e, 'title')}
      />
    );
    const eventType = this.getEventType(item as ISearchEventDocument);
    return renderMode === EventListItemRenderMode.default ? (
      title
    ) : (
      <div>
        <div className={classes.condensedTitle}>
          <span
            className={st(classes.condensedTitleStrong, {
              narrow: !!eventType,
            })}
          >
            {title}
          </span>
          <div
            className={st(classes.condensedTitleEvent)}
            data-hook="event-type"
          >
            {eventType}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      environment,
      item,
      onLinkClick,
      thumbnailHeight,
      thumbnailWidth,
    } = this.props;
    const { isMobile, isEditorX } = environment;
    const { image, url, title } = item;

    return (
      <li
        className={st(classes.root, {
          fixed: !isEditorX,
          fluid: isEditorX,
          mobileView: isMobile,
        })}
        data-hook="list-layout-item"
      >
        {hasImage(image) && (
          <ListItemThumbnail
            height={thumbnailHeight}
            image={image}
            onLinkClick={onLinkClick}
            title={title}
            url={url}
            width={thumbnailWidth}
          />
        )}
        <div className={classes.content}>
          {this.renderTitle()}
          {this.renderDescription()}
        </div>
      </li>
    );
  }
}

export const EventListItem = withEnvironment(EventListItemComp);
