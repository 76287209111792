import * as React from 'react';

import { ImageResizeOptions, ThumbnailImage } from 'wix-ui-tpa';

import { IResizedThumbnailProps } from './ResizedThumbnail';

type ICroppedThumbnailProps = Pick<
  IResizedThumbnailProps,
  'width' | 'height' | 'src' | 'loadingBehavior'
>;

export const CroppedThumbnail: React.FC<ICroppedThumbnailProps> = (props) => {
  return <ThumbnailImage {...props} resize={ImageResizeOptions.cover} />;
};
