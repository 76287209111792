import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  // Search bar
  searchBarBackgroundColor: StyleParamType.Color;
  searchBarBorderWidth: StyleParamType.Number;
  searchBarBorderColor: StyleParamType.Color;
  searchBarTextFont: StyleParamType.Font;
  searchBarTextFontColor: StyleParamType.Color;
  searchBarIconColor: StyleParamType.Color;

  // Results Menu
  resultsMenuTextFont: StyleParamType.Font;
  resultsMenuTextFontColor: StyleParamType.Color;
  resultsMenuBorderColor: StyleParamType.Color;
  resultsMenuSelectedBorderColor: StyleParamType.Color;

  // Search Results
  resultsTitleFont: StyleParamType.Font;
  resultsTitleFontColor: StyleParamType.Color;
  resultsDescriptionFont: StyleParamType.Font;
  resultsDescriptionFontColor: StyleParamType.Color;
  resultsMessageFont: StyleParamType.Font;
  resultsMessageFontColor: StyleParamType.Color;
  resultsHighlightColor: StyleParamType.Color;
  resultsHighlightFont: StyleParamType.Font;
  resultsHighlightFontColor: StyleParamType.Color;

  // Pagination
  paginationTextFont: StyleParamType.Font;
  paginationTextFontColor: StyleParamType.Color;
  paginationSelectedTextFontColor: StyleParamType.Color;

  // Add To Cart Button
  addToCartButtonFont: StyleParamType.Font;
  addToCartButtonTextColor: StyleParamType.Color;
  addToCartButtonBackgroundColor: StyleParamType.Color;

  // View All Button
  viewAllButtonFont: StyleParamType.Font;
  viewAllButtonTextColor: StyleParamType.Color;
  viewAllButtonBackgroundColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  searchBarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  searchBarBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  searchBarBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  searchBarTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  searchBarTextFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  searchBarIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  resultsMenuTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  resultsMenuTextFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  resultsMenuBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  resultsMenuSelectedBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  resultsTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  resultsTitleFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  resultsDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  resultsDescriptionFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  resultsMessageFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  resultsMessageFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  resultsHighlightColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  resultsHighlightFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  resultsHighlightFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  paginationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  paginationTextFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  paginationSelectedTextFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.6),
  },
  addToCartButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  addToCartButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  addToCartButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  viewAllButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  viewAllButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  viewAllButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
});
