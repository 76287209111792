import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  Pagination as TPAPagination,
  PaginationProps as TPAPaginationProps,
} from 'wix-ui-tpa';
import settingsParams from '../../../settingsParams';

import { classes, st } from './Pagination.st.css';

interface PaginationProps {
  totalPages: number | null;
  currentPage: number | null;
  onPageChange: TPAPaginationProps['onChange'];
  buildPageUrl(pageNumber: number): string;
}

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  buildPageUrl,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const paginationAlignment = settings.get(settingsParams.paginationAlignment);

  return currentPage && totalPages ? (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        alignment: paginationAlignment,
      })}
      data-hook="pagination-root"
    >
      <TPAPagination
        totalPages={totalPages}
        currentPage={currentPage}
        maxPagesToShow={
          isMobile
            ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE
            : MAX_PAGES_TO_SHOW_IN_PAGINATION.DESKTOP
        }
        pageUrl={buildPageUrl}
        onChange={onPageChange}
        data-hook="pagination"
        className={classes.pagination}
      />
    </div>
  ) : null;
};
