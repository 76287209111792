import { useMemo } from 'react';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { IWidgetRootProps } from './WidgetRoot';
import stylesParams from '../../../stylesParams';

interface HighlightStyle {
  bold: boolean;
  italic: boolean;
  underline: boolean;
}

const DEFAULT_HIGHLIGHT_STYLE: HighlightStyle = {
  bold: false,
  italic: false,
  underline: false,
};

/*
  Font weight, style and decoration can not be used for the highlighted search term using
  conventional methods. Using fully-wired tpa-settings and stylable it is only possible to
  have font as a whole css shorthand, which includes not only decorations but also a font
  value and its size. When font value and size is present, the font of highlighted text is
  overwritten. It causes problems because highlights can be both in the title and description
  areas, which have different fonts/sizes. Therefore the solution is to use font type provided
  by the tpa-settings and manually extract and set the specific font properties.
*/
const addHighlightStyle = (
  str: string,
  highlightStyle: HighlightStyle,
): string => {
  const { bold, italic, underline } = highlightStyle;
  let style = '';

  if (!bold && !italic && !underline) {
    return str;
  }

  if (bold) {
    style += 'font-weight:bold;';
  }

  if (italic) {
    style += 'font-style:italic;';
  }

  if (underline) {
    style += 'text-decoration:underline;';
  }

  return str.replace(new RegExp('<mark>', 'g'), `<mark style="${style}">`);
};

export const useHighlightStyle = ({
  searchResponse,
  searchSamples,
  ...props
}: IWidgetRootProps): IWidgetRootProps => {
  const styles = useStyles();

  const highlightStyle =
    styles.get(stylesParams.resultsHighlightFont).style ??
    DEFAULT_HIGHLIGHT_STYLE;

  searchResponse = useMemo(
    () => ({
      ...searchResponse,
      documents: searchResponse.documents.map((document) => ({
        ...document,
        title: addHighlightStyle(document.title, highlightStyle),
        description: addHighlightStyle(document.description, highlightStyle),
      })),
    }),
    [searchResponse, highlightStyle],
  );

  searchSamples = useMemo(
    () =>
      searchSamples.map((sample) => ({
        ...sample,
        documents: sample.documents.map((document) => ({
          ...document,
          title: addHighlightStyle(document.title, highlightStyle),
          description: addHighlightStyle(document.description, highlightStyle),
        })),
      })),
    [searchSamples, highlightStyle],
  );

  return {
    ...props,
    searchResponse,
    searchSamples,
  };
};
