import React, { useMemo } from 'react';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import { FloatingDropdown } from 'wix-ui-tpa';

import { ISearchResultsProps } from '../SearchResults';
import { useAppSettings } from '../../hooks';
import { SortOption } from '../../../../../lib/sort';
import { Spec } from '../../../../../lib/specs';

import { classes } from './SortControl.st.css';

export type SortControlProps = Pick<
  ISearchResultsProps,
  'onSortChange' | 'selectedSortOption' | 'selectedDocumentType'
>;

interface SortOptionItem {
  sortOption: SortOption;
  translationKey: string;
}

const customSortOptionsByDocumentType: Partial<
  Record<SearchDocumentType, SortOptionItem[]>
> = {
  [SearchDocumentType.Forums]: [
    {
      sortOption: SortOption.Recent,
      translationKey:
        'searchResults.sort.dropdownOptions.forum.lastActivityDate.desc',
    },
    {
      sortOption: SortOption.Newest,
      translationKey:
        'searchResults.sort.dropdownOptions.forum.createdDate.desc',
    },
    {
      sortOption: SortOption.MostComments,
      translationKey:
        'searchResults.sort.dropdownOptions.forum.totalComments.desc',
    },
    {
      sortOption: SortOption.MostViewed,
      translationKey: 'searchResults.sort.dropdownOptions.forum.viewCount.desc',
    },
    {
      sortOption: SortOption.MostLiked,
      translationKey: 'searchResults.sort.dropdownOptions.forum.likeCount.desc',
    },
  ],
  [SearchDocumentType.Events]: [
    {
      sortOption: SortOption.Closest,
      translationKey:
        'searchResults.sort.dropdownOptions.events1.startDate.desc',
    },
  ],
  [SearchDocumentType.Blogs]: [
    {
      sortOption: SortOption.Newest,
      translationKey:
        'searchResults.sort.dropdownOptions.blog.publishDate.desc',
    },
  ],
  [SearchDocumentType.Products]: [
    {
      sortOption: SortOption.PriceAscending,
      translationKey: 'searchResults.sort.dropdownOptions.products.price.asc',
    },
    {
      sortOption: SortOption.PriceDescending,
      translationKey: 'searchResults.sort.dropdownOptions.products.price.desc',
    },
  ],
};

const defaultSortOption: SortOptionItem = {
  sortOption: SortOption.BestMatch,
  translationKey: 'searchResults.sort.options.default',
};

export const SortControl: React.FC<SortControlProps> = ({
  selectedSortOption,
  selectedDocumentType,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const appSettings = useAppSettings();

  const showProductPrice =
    !experiments.enabled(Spec.ProductPriceVisibility) ||
    appSettings.isProductPriceVisible;

  const options = useMemo(() => {
    const customOptions = (
      customSortOptionsByDocumentType[selectedDocumentType] ?? []
    ).filter(({ sortOption }) => {
      if (
        selectedDocumentType === SearchDocumentType.Products &&
        !showProductPrice
      ) {
        return ![
          SortOption.PriceAscending,
          SortOption.PriceDescending,
        ].includes(sortOption);
      }

      return true;
    });

    if (!customOptions.length) {
      return [];
    }

    return [defaultSortOption, ...customOptions].map(
      ({ sortOption, translationKey }) => ({
        id: sortOption,
        isSelectable: true,
        value: t(translationKey),
      }),
    );
  }, [selectedDocumentType, showProductPrice, t]);

  if (!options.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <FloatingDropdown
        data-hook="search-sort-dropdown"
        placement="bottom-end"
        onChange={({ id }) => {
          onSortChange(id as SortOption);
        }}
        label={t('searchResults.sort.dropdown.label')}
        value={selectedSortOption}
        options={options}
        removeElementMinWidth
      />
    </div>
  );
};
