import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Thumbnail, IThumbnailProps } from '../Thumbnail';
import { ILayoutItemProps } from '../Layout.types';

import { classes } from './ListItemThumbnail.st.css';

export const LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH = 176;
export const LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT = 124;

type ListItemThumbnailProps = {
  height?: number;
  image: IThumbnailProps['image'];
  onLinkClick: ILayoutItemProps['onLinkClick'];
  title: string;
  url?: string;
  width?: number;
};

export const ListItemThumbnail: React.FC<ListItemThumbnailProps> = ({
  height = LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT,
  image,
  onLinkClick,
  title,
  url,
  width = LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH,
}) => {
  const { isMobile, isEditorX } = useEnvironment();

  if (!isEditorX && isMobile) {
    return null;
  }

  const thumbnail = (
    <Thumbnail
      height={height}
      image={image}
      maxResponsiveImageHeight={516}
      maxResponsiveImageWidth={516}
      onClick={(e) => onLinkClick?.(e, 'image')}
      title={title}
      url={url}
      width={width}
    />
  );

  return isEditorX ? (
    <div className={classes.thumbnailContainer} style={{ minWidth: width }}>
      {thumbnail}
    </div>
  ) : (
    thumbnail
  );
};
