import * as React from 'react';
import { CircularProgressBar } from 'wix-ui-core';

import { classes } from './Spinner.st.css';

interface ISpinnerProps {
  dataHook?: string;
}

interface ISpinnerState {
  value: number;
  increment: number;
}

const MIN = 25;
const MAX = 75;

const getIncrement = (value: number, currentIncrement: number): number => {
  return (value > MAX && currentIncrement === 1) ||
    (value < MIN && currentIncrement === -1)
    ? currentIncrement * -1
    : currentIncrement;
};

export class Spinner extends React.Component<ISpinnerProps, ISpinnerState> {
  state = {
    value: MIN,
    increment: 1,
  };
  interval: any;

  componentDidMount(): void {
    this.interval = setInterval(() => {
      const { value } = this.state;
      const increment = getIncrement(value, this.state.increment);
      this.setState({
        value: value + increment,
        increment,
      });
    }, 500);
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  render() {
    const { dataHook } = this.props;
    return (
      <CircularProgressBar
        className={classes.root}
        value={this.state.value}
        data-hook={dataHook}
      />
    );
  }
}
